<template>
  <nav class="site-menu">
    <ul class="site-menu__container">
      <li class="site-menu__container__item">
        <a @click.prevent="emit('goTo', '#features')" class="site-menu__container__item__link" href="/#features">
          Fonctionnalités
        </a>
      </li>
      <!-- <li class="site-menu__container__item">
        <NuxtLink
          :prefetch="false" 
          class="site-menu__container__item__link"
          :to="{
            path: '/blog'
          }"
        >
          Blog
        </NuxtLink>
      </li> -->
      <li class="site-menu__container__item">
        <a @click.prevent="emit('goTo', '#price')" class="site-menu__container__item__link" href="/#price">
          Prix
        </a>
      </li>
      <li class="site-menu__container__item">
        <NuxtLink
          :prefetch="false" 
          :to="{
            name: 'blog'
          }"
          class="site-menu__container__item__link"
        >
          Blog
        </NuxtLink>
      </li>
      <li class="site-menu__container__item">
        <div class="site-menu__container__item__action">
          <slot  />
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup>
  const emit = defineEmits(['goTo'])
</script>


<style lang="scss" scoped>
  @use './index.scss';
</style>