<template>
  <header class="site-header">
    <div class="site-header__wrapper">
      <NuxtLink
        :prefetch="false" 
        class="site-header__wrapper__link"
        :to="{
          name: 'home',
        }"
      >
        <SiteLogo
          class="site-header__wrapper__logo"
        />
        <SiteLogoTitle
          class="site-header__wrapper__title"
        />
      </NuxtLink>
      <div
        class="site-header__wrapper__spacer"
      />
      <SiteMenu
        class="site-header__wrapper__menu site-menu--inline"
        @go-to="goTo"
      >
        <SiteMenuButton
          class="site-menu-button--inline"
          :label="siteIdentity.authentity.value ? 'Revenir' : 'Connexion'"
          :href="href"
          @click.prevent="loginOrGo"
        />
      </SiteMenu>
      <SiteMobileMenu
        class="site-header__wrapper__mobile-menu"
        :open="open"
        @login="login"
        @open="tryOpen"
      />
      <SiteBurger
        class="site-header__wrapper__burger"
        :open="open"
        @open="tryOpen"
      />
    </div>
    <GlobalEvents
      v-if="open"
      target="window"
      @resize="closeIfSmall"
    />
  </header>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import SiteLogo from '@/components/SiteLogo'
  import SiteBurger from '@/sections/SiteHeader/SiteBurger'
  import SiteLogoTitle from '@/components/SiteLogoTitle'
  import SiteMobileMenu from '@/sections/SiteHeader/SiteMobileMenu'
  import SiteMenu from '@/sections/SiteHeader/SiteMenu'
  import SiteMenuButton from '@/sections/SiteHeader/SiteMenuButton'
  import { useOverlay } from '@/components/SiteOverlay/useOverlay'
  import { GlobalEvents } from 'vue-global-events'
  import useSiteIdentity from '@/components/SiteIdentity'
  
  const siteIdentity = useSiteIdentity()

  const router = await useRouter()

  const emit = defineEmits(['hash', 'login'])

  const config = useRuntimeConfig()

  const closeIfSmall = () => {
    if (window.innerWidth > 672) {
      tryOpen(false)
    }
  }

  const overlay = useOverlay()
  const open = ref(false)

  const login = () => {
    tryOpen(false)
    setTimeout(() => {
      emit('login')
    }, 620)
  }

  const tryOpen = (value) => {
    if (value && !overlay.reallyVisible.value) {
      open.value = true
      overlay.set(true)
      overlay.subscribeClose(() => {
        tryOpen(false)
      })
    } else if (!value) {
      open.value = false
      overlay.set(false)
    }
  }

  const goTo = (hash) => {
    router.push({
      path: '/',
      hash
    })
  }

  const href = computed(() => {
    if (siteIdentity.authentity.value && siteIdentity.identity.value?.isTemp) {
      return config.public.craAppUrl
    } else if (siteIdentity.authentity.value && siteIdentity.identity.value) {
      return config.public.freelanceAppUrl
    } else {
      return undefined
    }
  })

  const loginOrGo = () => {
    const siteIdentity = useSiteIdentity()
    if (siteIdentity.authentity.value && siteIdentity.identity.value.isTemp) {
      location.href = `${config.public.craAppUrl}?auth_token=${siteIdentity.authentity.value}`
    } else if (siteIdentity.authentity.value && siteIdentity.identity.value) {
      location.href = config.public.freelanceAppUrl
    } else {
      emit('login')
    }
  }
</script>

<style lang="scss">
  @use './index.scss';
</style>