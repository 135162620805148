<template>
  <footer class="site-post-footer">
    <div class="site-post-footer__wrapper">
      <div class="site-post-footer__wrapper__copy">
        Freelance Régie Tools, tout droit réservé 2023
      </div>
      <div class="site-post-footer__wrapper__cgu">
        <NuxtLink
          :to="{
            name: 'legal'
          }"
          :prefetch="false"
        >Mentions Legales</NuxtLink>
        <span class="site-post-footer__wrapper__cgu__separator"> | </span>
        <NuxtLink
          :prefetch="false"
          :to="{
            name: 'rgpd'
          }">Sécurité des données</NuxtLink>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">

  $desktop-breakpoint: 990px;
  $tablet-breakpoint: 672px;
  
  .site-post-footer {
    background-color: #131427;
    font-weight: 300;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #FFFFFF30;

    &__wrapper {
      max-width: 1230px;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;

      display: flex;
      flex-direction: column;
      @media screen and (min-width: $desktop-breakpoint) {
        flex-direction: row;
        justify-content: center;
      }
      
      &__copy {
        color: white;
        font-size: 18px;
        text-align: center;
        margin: 5px;
        @media screen and (min-width: $desktop-breakpoint) {
          flex-grow: 1;
          text-align: left;
        }
      }

      &__cgu {
        text-align: center;
        color: white;
        font-size: 16px;
        margin: 5px;
        @media screen and (min-width: $desktop-breakpoint) {
          flex-grow: 1;
          text-align: right;
        }
        a {
          color: white;
          text-decoration: none;

        }

        &__separator {
          @media screen and (min-width: $desktop-breakpoint) {
            visibility: hidden;
            margin: 0 10px;
          }
        }
      }
    }
  }
</style>