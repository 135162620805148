<template>
  <div class="site-mobile-menu">

    <Transition name="fade">
      <SiteMenu
        v-if="props.open"
        @go-to="goButWait"
        class="site-mobile-menu__menu"
      >
        <SiteMenuButton
          class="site-menu-button"
          :label="siteIdentity.authentity.value ? 'Revenir' : 'Connexion'"
          :href="href"
          @click.prevent="loginOrGo"
        />
      </SiteMenu>
    </Transition>

    <Transition name="move">
      <div
        v-if="props.open"
        class="site-mobile-menu__background"
      />
    </Transition>
  </div>
  
</template>


<script setup>
  import SiteMenu from '@/sections/SiteHeader/SiteMenu'
  import SiteMenuButton from '@/sections/SiteHeader/SiteMenuButton'
  import useSiteIdentity from '@/components/SiteIdentity'

  const siteIdentity = useSiteIdentity()

  const router = await useRouter()

  const config = useRuntimeConfig()

  const props = defineProps({
    open: Boolean
  })

  const emit = defineEmits(['open', 'login'])

  const goButWait = (target) => {
    emit('open', false)
    if (target) {
      setTimeout(() => {
        router.push(target)
      }, 630)
    }
  }

  const href = computed(() => {
    if (siteIdentity.authentity.value && siteIdentity.identity.value?.isTemp) {
      return config.public.craAppUrl
    } else if (siteIdentity.authentity.value && siteIdentity.identity.value) {
      return config.public.freelanceAppUrl
    } else {
      return undefined
    }
  })

  const loginOrGo = () => {
    const siteIdentity = useSiteIdentity()
    if (siteIdentity.authentity.value && siteIdentity.identity.value.isTemp) {
      emit('open', false)
      setTimeout(() => {
        location.href = `${config.public.craAppUrl}?auth_token=${siteIdentity.authentity.value}`
      }, 630)
    } else if (siteIdentity.authentity.value && siteIdentity.identity.value) {
      location.href = config.public.freelanceAppUrl
    } else {
      emit('login')
    }
  }
</script>
<style lang="scss" scoped>
  @use './index.scss';
</style>